var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('LayoutBox',{attrs:{"loading":_vm.loading,"title":"人员识别统计"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","height":"100%"}},[_c('Tabs',{staticStyle:{"margin":"16px 40px"},attrs:{"options":_vm.customerlist,"replace-fields":{
        type: 'code',
        lable: 'text',
        value: 'count',
      }},model:{value:(_vm.queryParam.customerType),callback:function ($$v) {_vm.$set(_vm.queryParam, "customerType", $$v)},expression:"queryParam.customerType"}}),_c('ScrollTable',{ref:"scrollTable",staticStyle:{"flex":"1","overflow":"hidden"},attrs:{"is-auto":false,"is-global-query":"","columns":_vm.columns,"query":Object.assign({}, {isData: '01'}, _vm.queryParam),"url":("/" + _vm.$businessISG + "/monitor/hall/customer-page")},scopedSlots:_vm._u([{key:"default-avatarUrl",fn:function(ref){
      var row = ref.row;
return [_c('v-avatar',{attrs:{"color":"grey lighten-2","rounded":"","size":"36"}},[_c('img',{attrs:{"src":row.attachList && row.attachList[0]
                ? (_vm.$imgUrl + "/" + (row.attachList[0]))
                : require('@/assets/screen/hallAssistant/default_avatar.png')}})])]}}]),model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }