<template>
  <LayoutBox :loading="loading" title="人员识别统计">
    <div style="display: flex; flex-direction: column; height: 100%">
      <Tabs
        v-model="queryParam.customerType"
        :options="customerlist"
        :replace-fields="{
          type: 'code',
          lable: 'text',
          value: 'count',
        }"
        style="margin: 16px 40px"
      ></Tabs>
      <ScrollTable
        ref="scrollTable"
        v-model="loading"
        :is-auto="false"
        is-global-query
        :columns="columns"
        :query="{ isData: '01', ...queryParam }"
        :url="`/${$businessISG}/monitor/hall/customer-page`"
        style="flex: 1; overflow: hidden"
      >
        <template slot="default-avatarUrl" slot-scope="{ row }">
          <v-avatar color="grey lighten-2" rounded size="36">
            <img
              :src="
                row.attachList && row.attachList[0]
                  ? `${$imgUrl}/${row.attachList[0]}`
                  : require('@/assets/screen/hallAssistant/default_avatar.png')
              "
            />
          </v-avatar>
        </template>
      </ScrollTable>
    </div>
  </LayoutBox>
</template>

<script>
import LayoutBox from '@/views/screen/components/LayoutBox.vue';
import ScrollTable from '@/views/screen/components/ScrollTable.vue';

export default {
  //组件注册
  components: {
    LayoutBox,
    Tabs: () => import('../components/Tabs.vue'),
    ScrollTable,
  },
  //组件传值
  props: {
    globalQuery: {
      type: Object,
      default: () => ({}),
    },
    refreshKey: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      loading: false,
      queryParam: {},
      columns: [
        {
          title: '机构',
          field: 'organizationText',
          width: '20%',
        },
        {
          title: '图像',
          field: 'avatarUrl',
          width: '20%',
          slots: true,
        },
        {
          title: '姓名',
          field: 'customerName',
          width: '20%',
        },
        {
          title: '进入时间',
          field: 'warningTime',
          width: '40%',
        },
      ],
      customerlist: [],
    };
  },
  //计算属性
  computed: {},
  //属性监听
  watch: {
    queryParam: {
      deep: true,
      handler: function () {
        this.$nextTick(() => {
          this.$refs.scrollTable.refresh(this.queryParam);
        });
      },
    },
    globalQuery: {
      deep: true,
      immediate: true,
      handler: 'getCustomerlist',
    },
    refreshKey: {
      handler: function () {
        this.getCustomerlist('refreshKey');
      },
    },
  },
  //DOM访问
  mounted() {
  },
  //保存方法
  methods: {
    async getCustomerlist(type) {
      const data = await this.$http.get(
        `/${this.$businessISG}/monitor/hall/customerlist-stat`,
        this.globalQuery
      );
      if (data.code == 200 && data.data) {
        this.customerlist = data.data;
        if (type == 'refreshKey') {
          this.$refs.scrollTable.refresh();
        } else {
          this.queryParam = {
            customerType: data.data[0].code,
          };
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
